var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f-page',{attrs:{"title":_vm.$t('labels.documents')}},[(_vm.data)?_c('f-card',{attrs:{"title":_vm.$t('labels.files')}},[_c('f-row',[_c('f-col',{attrs:{"md":"12"}},[_c('f-upload',{attrs:{"label":_vm.$t('labels.taxFile'),"data":{
            name: _vm.data.taxFile,
            url: _vm.data.taxFileUrl
          }},on:{"input":function (file) { return _vm.uploadNetworkFiles('taxFile', file); }}})],1),_c('f-col',{attrs:{"md":"12"}},[_c('f-upload',{attrs:{"label":_vm.$t('labels.tradeRegistryFile'),"data":{
            name: _vm.data.tradeRegistryFile,
            url: _vm.data.tradeRegistryFileUrl
          }},on:{"input":function (file) { return _vm.uploadNetworkFiles('tradeRegistryFile', file); }}})],1),_c('f-col',{attrs:{"md":"12"}},[_c('f-upload',{attrs:{"label":_vm.$t('labels.contractFile'),"data":{
            name: _vm.data.contractFile,
            url: _vm.data.contractFileUrl
          }},on:{"input":function (file) { return _vm.uploadNetworkFiles('contractFile', file); }}})],1),_c('f-col',{attrs:{"md":"12"}},[_c('f-upload',{attrs:{"label":_vm.$t('labels.vehicleListFile'),"data":{
            name: _vm.data.vehicleListFile,
            url: _vm.data.vehicleListFileUrl
          }},on:{"input":function (file) { return _vm.uploadNetworkFiles('vehicleListFile', file); }}})],1),_c('f-col',{attrs:{"md":"12"}},[_c('f-upload',{attrs:{"label":_vm.$t('labels.staffListFile'),"data":{
            name: _vm.data.staffListFile,
            url: _vm.data.staffListFileUrl
          }},on:{"input":function (file) { return _vm.uploadNetworkFiles('staffListFile', file); }}})],1)],1)],1):_vm._e(),_c('f-card',{attrs:{"title":_vm.$t('labels.vehicleRegistration')}},[_c('f-row',[_c('f-col',{attrs:{"md":"12"}},[_c('f-upload',{attrs:{"drag-drop":"","multiple":"","accept":"*/*","placeholder":_vm.$t('labels.uploadAttachment')},on:{"input":_vm.uploadVehicleRegistrationFiles}})],1),_c('f-col',{attrs:{"md":"12"}},[_c('f-datatable',{ref:"vehicleRegistrationFileDataTable",attrs:{"columns":_vm.vehicleRegistrationFileColumns,"data-url":"/networkApp/vehicleRegistrationFiles","features":{
            delete: _vm.hasEditPermission,
            pagination: false,
            fullscreen: false,
            showColumns: false
          }},on:{"delete":_vm.deleteVehicleRegistrationFile}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }