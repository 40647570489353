<template>
  <f-page :title="$t('labels.documents')">
    <f-card v-if="data" :title="$t('labels.files')">
      <f-row>
        <f-col md="12">
          <f-upload
            :label="$t('labels.taxFile')"
            :data="{
              name: data.taxFile,
              url: data.taxFileUrl
            }"
            @input="file => uploadNetworkFiles('taxFile', file)"
          ></f-upload>
        </f-col>
        <f-col md="12">
          <f-upload
            :label="$t('labels.tradeRegistryFile')"
            :data="{
              name: data.tradeRegistryFile,
              url: data.tradeRegistryFileUrl
            }"
            @input="file => uploadNetworkFiles('tradeRegistryFile', file)"
          ></f-upload>
        </f-col>
        <f-col md="12">
          <f-upload
            :label="$t('labels.contractFile')"
            :data="{
              name: data.contractFile,
              url: data.contractFileUrl
            }"
            @input="file => uploadNetworkFiles('contractFile', file)"
          ></f-upload>
        </f-col>
        <f-col md="12">
          <f-upload
            :label="$t('labels.vehicleListFile')"
            :data="{
              name: data.vehicleListFile,
              url: data.vehicleListFileUrl
            }"
            @input="file => uploadNetworkFiles('vehicleListFile', file)"
          ></f-upload>
        </f-col>
        <f-col md="12">
          <f-upload
            :label="$t('labels.staffListFile')"
            :data="{
              name: data.staffListFile,
              url: data.staffListFileUrl
            }"
            @input="file => uploadNetworkFiles('staffListFile', file)"
          ></f-upload>
        </f-col>
      </f-row>
    </f-card>
    <f-card :title="$t('labels.vehicleRegistration')">
      <f-row>
        <f-col md="12">
          <f-upload
            drag-drop
            multiple
            accept="*/*"
            :placeholder="$t('labels.uploadAttachment')"
            @input="uploadVehicleRegistrationFiles"
          />
        </f-col>

        <f-col md="12">
          <f-datatable
            ref="vehicleRegistrationFileDataTable"
            :columns="vehicleRegistrationFileColumns"
            :data-url="`/networkApp/vehicleRegistrationFiles`"
            :features="{
              delete: hasEditPermission,
              pagination: false,
              fullscreen: false,
              showColumns: false
            }"
            @delete="deleteVehicleRegistrationFile"
          ></f-datatable>
        </f-col>
      </f-row>
    </f-card>
  </f-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { vehicleRegistrationFileColumns } from '@/data/columns'

export default {
  data () {
    return {
      data: null,
      networkId: null,
      hasEditPermission: true,
      vehicleRegistrationFileColumns: vehicleRegistrationFileColumns
    }
  },
  computed: { ...mapGetters(['user']) },
  methods: {
    async loadUpdateInfo () {
      const updateInfo = await this.$http.get('/networkApp/updateInfo')
      this.data.updatedBy = updateInfo.updatedBy
      this.data.updatedAt = updateInfo.updatedAt
    },
    async uploadNetworkFiles (name, file) {
      const formData = new FormData()
      formData.append(`${name}_file`, file)
      const uploadedFile = await this.$http.post(
        `/networkApp/networkDocuments/${name}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      this.data[name] = uploadedFile[name]
      this.data[`${name}Url`] = uploadedFile[`${name}Url`]
      await this.loadUpdateInfo()
    },
    async getAttachmentData () {
      this.data = await this.$http.get('/networkApp/network')
    },
    async loadVehicleRegistrationFiles () {
      this.$refs.vehicleRegistrationFileDataTable.refresh()
    },
    async uploadVehicleRegistrationFiles (vehicleRegistrationFileList) {
      for (const vehicleRegistrationFile of vehicleRegistrationFileList) {
        const formData = new FormData()
        formData.append('vehicleRegistrationFile', vehicleRegistrationFile)
        await this.$http.post(
          '/networkApp/vehicleRegistrationFiles',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
      }
      await this.loadUpdateInfo()
      await this.loadVehicleRegistrationFiles()
    },
    async deleteVehicleRegistrationFile (row) {
      await this.$http.delete(`/networkApp/vehicleRegistrationFiles/${row.id}`)
      await this.loadUpdateInfo()
      await this.loadVehicleRegistrationFiles()
    }
  },
  mounted () {
    this.getAttachmentData()
  }
}
</script>
